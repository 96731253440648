var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"light":""}},[_c('v-app-bar-title',[_vm._v(" Задачи Поисковых Систем ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","href":"https://docs.google.com/document/d/1yrTSdu5h6B4uFew3PY7GjCagfgCTnED0/edit?usp=share_link&ouid=101756017258060840726&rtpof=true&sd=true","target":"_blank"}},[_c('v-icon',{attrs:{"large":"","color":"grey"}},[_vm._v("help")])],1),_c('v-btn',{attrs:{"color":"primary","to":"/main/se-tasks/create"}},[_vm._v("Добавить задачу")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableLoaded ? _vm.tasks : [],"items-per-page":10,"loading":!_vm.tableLoaded,"loading-text":"Загрузка... Подождите"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.id))]),_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(props.item.description))]),_c('td',[_vm._v(_vm._s(props.item.owner.full_name))]),_c('td',_vm._l((props.item.sources),function(se_name,i){return _c('v-chip',{key:se_name + i,staticClass:"ma-2",attrs:{"small":""}},[_vm._v(_vm._s(se_name))])}),1),_c('td',[_vm._v(_vm._s(props.item.deep))]),_c('td',[_vm._v(_vm._s(new Date(props.item.datetime_add).toLocaleString()))]),_c('td',[_vm._v(_vm._s(props.item.datetime_end ? new Date(props.item.datetime_end).toLocaleString() : ''))]),_c('td',{staticStyle:{"text-align":"center","padding":"8px"}},[_vm._v(" "+_vm._s(props.item.status)),_c('br'),(props.item.stats)?_c('sub',[_c('div',{staticStyle:{"display":"flex","gap":"4px","justify-content":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.sourceExists(props.item.sources, 'yandex_ru'))?_c('v-progress-circular',_vm._g({attrs:{"rotate":-90,"size":30,"width":6,"value":_vm.getPercent(props.item.stats, 'yandex_ru'),"color":"pink"}},on),[_vm._v("Y")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Yandex:"),_c('br'),_vm._v(" "+_vm._s(props.item.stats.yandex_ru)+"/"+_vm._s(props.item.stats.total_count)+" "),_vm._v(" "+_vm._s(_vm.getPercent(props.item.stats, 'yandex_ru'))+"%"),_c('br'),_vm._v(" "+_vm._s(_vm.showEstimateTimeToGo( props.item.stats.total_count, props.item.stats.yandex_ru_prev, props.item.stats.yandex_ru, props.item.stats.yandex_prev_time )))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.sourceExists(props.item.sources, 'google'))?_c('v-progress-circular',_vm._g({attrs:{"rotate":-90,"size":30,"width":6,"value":_vm.getPercent(props.item.stats, 'google'),"color":"teal"}},on),[_vm._v("G")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"center"},[_vm._v("Google:"),_c('br'),_vm._v(" "+_vm._s(props.item.stats.google)+"/"+_vm._s(props.item.stats.total_count)+" "+_vm._s(_vm.getPercent(props.item.stats, 'google'))+"%"),_c('br'),_vm._v(" "+_vm._s(_vm.showEstimateTimeToGo( props.item.stats.total_count, props.item.stats.google_prev, props.item.stats.google, props.item.stats.google_prev_time )))])])],1)]):_vm._e()]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!['ACTIVE', 'WAIT'].includes(props.item.status)),expression:"!['ACTIVE', 'WAIT'].includes(props.item.status)"}],attrs:{"small":"","text":"","disabled":!['NEW','PROBLEM','CANCEL'].includes(props.item.status)},on:{"click":function($event){props.item.status = 'START'; _vm.runTask(props.item.id);}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-play")])],1)]}}],null,true)},[_c('span',[_vm._v("Запустить")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(['ACTIVE', 'WAIT'].includes(props.item.status)),expression:"['ACTIVE', 'WAIT'].includes(props.item.status)"}],attrs:{"small":"","text":""},on:{"click":function($event){return _vm.killTask(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-stop")])],1)]}}],null,true)},[_c('span',[_vm._v("Прервать")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","to":{name: 'se-task-edit', params: {id: props.item.id}},"disabled":!['NEW','PROBLEM'].includes(props.item.status)}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Изменить")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(
                  props.item.status !== 'EXPORT' || 
                  (props.item.status === 'EXPORT' && props.item.result.endsWith('.zip'))
                ),expression:"\n                  props.item.status !== 'EXPORT' || \n                  (props.item.status === 'EXPORT' && props.item.result.endsWith('.zip'))\n                "}],attrs:{"small":"","text":"","to":{
                  name: 'se-task-export', params: {id: props.item.id, result: props.item.result}
                },"disabled":props.item.isExporting || 
                  props.item.isDownloading || 
                  (props.item.status !== 'ENDED')}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-export")])],1)]}}],null,true)},[_c('span',[_vm._v("Экспорт отчетов")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(['EXPORT'].includes(props.item.status) && !props.item.result.endsWith('.zip')),expression:"['EXPORT'].includes(props.item.status) && !props.item.result.endsWith('.zip')"}],attrs:{"small":"","text":""},on:{"click":function($event){return _vm.killExportTask(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("fas fa-file-export")])],1)]}}],null,true)},[_c('span',[_vm._v("Прервать экспорт")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","disabled":props.item.isExporting || 
                  props.item.isDownloading || 
                  !props.item.result || 
                  !props.item.result.includes('.zip')},on:{"click":function($event){return _vm.downloadSeTaskResult(props.item.id, props.item.result)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Скачать: "+_vm._s(props.item.result))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","disabled":['ACTIVE', 'START', 'WAIT'].includes(props.item.status)},on:{"click":function($event){return _vm.deleteTask(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash-alt")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }