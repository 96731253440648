















































































































































































































import { Component, Vue } from 'vue-property-decorator';

import { ISeTaskStats } from '@/interfaces/se-tasks';
import {
  dispatchDeleteSeTask,
  dispatchDownloadSeTaskResult,
  dispatchGetSeTasks,
  dispatchKillExportSeTask,
  dispatchKillSeTask,
  dispatchRunSeTask,
  dispatchStatSeTasks,
} from '@/store/se-tasks/actions';
import { readSeTasks } from '@/store/se-tasks/getters';
import { SE_TASK_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';

@Component
export default class Tasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    {
      text: 'Поисковики',
      sortable: true,
      value: 'sources',
      align: 'left',
    },
    // {
    //   text: 'Ключи',
    //   sortable: true,
    //   value: 'query_suffixes',
    //   align: 'left',
    // },
    {
      text: 'Глубина',
      sortable: true,
      value: 'deep',
      align: 'left',
    },
    {
      text: 'Дата создания',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Дата завершения',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readSeTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetSeTasks(this.$store);
    await dispatchStatSeTasks(this.$store, {filter: {all: true}});
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetSeTasks(this.$store);
      await dispatchStatSeTasks(this.$store, {filter: {all: true}});
      }, SE_TASK_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeleteSeTask(this.$store, {id: taskId});
    await dispatchGetSeTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunSeTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillSeTask(this.$store, {id: taskId});
  }

  public async killExportTask(taskId: number) {
    await dispatchKillExportSeTask(this.$store, {id: taskId});
  }

  public async downloadSeTaskResult(taskId: number, result: string) {
    await dispatchDownloadSeTaskResult(this.$store, { id: taskId, result });
  }

  public sourceExists(sources: string[], source: string) {
    return sources.find((item) => (item === source));
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: ISeTaskStats, field: string) {
    return Math.floor(statData[field] / statData.total_count * 100);
  }
}
